<template>
  <label v-if="label" class="form-check-label">
    {{ label }}
    <BaseRequiredLabel v-if="required" />
  </label>
  <div class="w-100 input-group has-validation">
    <select
      :value="modelValue"
      :disabled="disabled"
      class="form-select"
      :required="required"
      @change="emitInput"
    >
      <option selected disable hidden />
      <option
        v-for="option in options"
        :key="option"
        :value="option.hasOwnProperty('value') ? option.value : option"
      >
        {{ option.hasOwnProperty('value') ? option.label : option }}
      </option>
    </select>
    <div v-if="error" class="invalid-feedback">
      {{ error[0] }}
    </div>
  </div>
</template>

<script>
import BaseRequiredLabel from '@/components/Base/BaseRequiredLabel.vue'

export default {
  name: 'BaseSelectInput',
  components: {
    BaseRequiredLabel,
  },
  props: {
    disabled: {
      type: Boolean,
    },

    options: {
      type: Array,
      default: () => [],
    },
    error: {
      type: [String, Number, Array],
      default: null,
    },

    inline: {
      type: Boolean,
      default: true,
    },

    additionalClass: {
      type: String,
      default: '',
    },

    modelValue: {
      type: [String, Number],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    emitInput(event) {
      this.$emit('update:modelValue', event.target.value)
    },
  },
}
</script>

<style lang="scss"></style>
