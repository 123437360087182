<template>
  <button
    class="btn"
    :class="`${
      'btn-' + color + ' ' + 'text-' + textColor + ' ' + rounded + ''
    }`"
    :disabled="disabled || loading"
    :type="type"
    :to="to"
    @click="$emit('click')"
  >
    <slot v-if="!loading" />
    <div v-else class="spinner-border" small role="status" />
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    textColor: {
      type: String,
      default: 'white',
    },
    pillMode: {
      type: Boolean,
      default: true,
    },

    color: {
      type: String,
      default: 'primary',
    },
    type: {
      type: String,
      default: 'button',
    },
    to: {
      type: [Object, String],
      default: null,
    },
  },
  emits: ['click'],
  computed: {
    rounded: function () {
      if (this.pillMode) {
        return 'rounded-pill'
      } else return ''
    },
  },
}
</script>

<style lang="scss"></style>
