<template>
  <div v-if="pageReady">
    <form class="vue-form px-5" @submit.prevent="submit">
      <fieldset>
        <h1 class="mb-3 text-dark"><strong>Referrer's Information</strong></h1>

        <div class="d-flex justify-content-between align-items-end pb-4">
          <Select2
            v-model="BasicReferrerInfo"
            class="d-flex flex-grow-1"
            :label-text="`Please search for existing referrer's name:`"
            :data-array="referralList"
            :api-call-name="`getReferralList`"
            :input-name="`Referrer`"
            @selecting="clearNewInput('referrer')"
          >
            <template #no-options>
              <p class="pt-2 m-0 text-gray-500">
                Existing referrer name not found, please create a new
                referrer...
              </p>
            </template>

            <template #option="option">
              <div
                v-if="option"
                class="py-3 px-2 vs-dropdown-listing rounded-3"
              >
                <span class="d-block text-capitalize fw-bold">
                  {{ option.title + ' ' }} {{ option.first_name }}
                </span>
                <em>
                  {{ option.email }} |
                  {{
                    option.company_name
                      ? option.company_name
                      : 'Unknown company'
                  }}
                  |
                  {{
                    option.address ? option.address : 'Unknown business address'
                  }}
                </em>
              </div>
            </template>
          </Select2>

          <BaseBtn
            :disabled="BasicReferrerInfo ? true : false"
            :class="`mx-3 mt-auto py-1 px-3`"
            @click="BasicReferrerInfo = {}"
          >
            + new referrer
          </BaseBtn>
        </div>

        <div
          v-if="BasicReferrerInfo"
          id="referral_section"
          class="card container my-5 p-4 shadow-sm"
          :class="badgeText.referrer === 'Selected' ? 'disable-div' : ''"
        >
          <span
            class="position-absolute top-0 start-10 translate-middle p-2 px-5 border border-light rounded-pill text-white"
            :class="badgeText.referrer === 'New' ? 'bg-success' : 'bg-primary'"
          >
            <strong>{{ badgeText.referrer }}</strong>
          </span>
          <div class="row my-3">
            <div class="col">
              <label class="form-check-label" for="referrer_title">
                Referrer's First Name
              </label>

              <div class="input-group">
                <select
                  v-model="BasicReferrerInfo.title"
                  if="referrer_title"
                  class="form-select"
                >
                  <option value="mr">Mr</option>
                  <option value="mrs">Mrs</option>
                  <option value="miss">Miss</option>
                </select>

                <input
                  id="referrer_name"
                  v-model="BasicReferrerInfo.first_name"
                  required
                  type="text"
                  class="form-control w-60"
                />
              </div>
            </div>

            <div class="col">
              <label class="form-check-label" for="referrer_name2">
                Middle Name
              </label>
              <input
                id="referrer_name2"
                v-model="BasicReferrerInfo.middle_name"
                type="text"
                class="form-control"
                aria-describedby="emailHelp"
              />
            </div>

            <div class="col">
              <label class="form-check-label" for="referrer_name3">
                Last Name
              </label>
              <input
                id="referrer_name3"
                v-model="BasicReferrerInfo.last_name"
                type="text"
                class="form-control"
                aria-describedby="emailHelp"
              />
            </div>
          </div>

          <div class="row my-3">
            <div class="col">
              <label class="form-check-label" for="reffererEmail">
                Referrer Email
              </label>
              <input
                id="reffererEmail"
                v-model="BasicReferrerInfo.email"
                required
                type="email"
                class="form-control"
                aria-describedby="emailHelp"
              />
            </div>
            <div class="col">
              <label class="form-check-label" for="company_name">
                Company Name
              </label>

              <input
                id="company_name"
                v-model="BasicReferrerInfo.company_name"
                class="form-control"
                type="text"
              />
            </div>
          </div>

          <div class="row my-3">
            <div class="col">
              <label class="form-check-label" for="referrals_address">
                Referrer Address
              </label>

              <textarea
                id="referrals_address"
                v-model="BasicReferrerInfo.address"
                required
                name="referrals_address"
                class="form-control"
                rows="1"
              />
            </div>
          </div>
        </div>

        <div class="my-5">
          <div class="row">
            <div class="col">
              <label class="form-check-label" for="referrals_code">
                Referrence
              </label>

              <input
                id="referrals_code"
                v-model="referral.referrence"
                class="form-control"
                type="text"
              />
            </div>
            <div class="col">
              <label class="form-check-label" for="letter_date">
                Referral letter date
              </label>

              <datepicker
                id="letter_date"
                v-model="referral.letter_date"
                :model-value="convertToDateFormat(referral.letter_date)"
                autocomplete="off"
                required
                input-format="dd MMMM yyyy"
                :upper-limit="currentYear"
                class="form-control"
                style="
                  --vdp-selected-bg-color: #32c3d6;
                  --vdp-hover-bg-color: #32c3d6;
                "
                :typeable="true"
              />
            </div>
          </div>

          <!-- requested assessment array -->
          <div class="row my-5">
            <div class="col-12 py-4">
              <label class="form-check-label" for="referrals_request">
                Referral Request
              </label>
              <editor
                id="referrals_request"
                v-model="referral.request"
                api-key="rqlshe39tnmswzraq3qm2yq3hs8z1dkr03i92xlachrasuh9"
                :init="{
                  plugins: 'lists link image table code help wordcount',
                }"
              />
            </div>

            <div class="col-12 py-4">
              <label class="form-check-label" for="referrals_history">
                Referral history
              </label>

              <div class="impairex-text-editor">
                <editor
                  id="referrals_history"
                  v-model="referral.history"
                  api-key="rqlshe39tnmswzraq3qm2yq3hs8z1dkr03i92xlachrasuh9"
                  :init="{
                    plugins: 'lists link image table code help wordcount',
                  }"
                />
              </div>
            </div>

            <div class="col-12 py-4">
              <label class="form-check-label" for="enclosed_docs">
                Enclosed documents:
              </label>
              <editor
                id="enclosed_docs"
                v-model="referral.enclosed_docs"
                api-key="rqlshe39tnmswzraq3qm2yq3hs8z1dkr03i92xlachrasuh9"
                :init="{
                  plugins: 'lists link image table code help wordcount',
                }"
              />
            </div>
          </div>
        </div>

        <h1 class="pt-5 mt-5 text-dark"><strong>Patient's Details</strong></h1>
        <div class="d-flex justify-content-between align-items-end pb-4">
          <Select2
            v-model="BasicPatientInfo"
            class="d-flex flex-grow-1"
            :label-text="`Please search for existing patient's name:`"
            :data-array="patientList"
            :api-call-name="`getPatientList`"
            :input-name="`Patient`"
            :dropdown-placeholder="`unable to find existing patient...`"
            @selecting="clearNewInput('patient')"
          >
            <template #no-options>
              Existing patient name not found, please create a new referrer...
            </template>
            <template #option="option">
              <div
                v-if="option"
                class="py-3 px-2 vs-dropdown-listing rounded-3"
              >
                <span class="d-block fw-bold text-capitalize">
                  {{ option.first_name }}
                </span>
                <em>
                  {{
                    option.gender === 'M'
                      ? 'Male'
                      : option.gender === 'F'
                      ? 'Female'
                      : 'Not Specified'
                  }}
                  , Born in {{ option.date_of_birth }}
                </em>
              </div>
            </template>
          </Select2>
          <BaseBtn
            :disabled="BasicPatientInfo ? true : false"
            :class="`mx-3 mt-auto py-1 px-3`"
            @click="BasicPatientInfo = {}"
          >
            + new patient
          </BaseBtn>
        </div>

        <div
          v-if="BasicPatientInfo"
          id="patient_info_section"
          class="card container my-5 p-4 shadow-sm"
          :class="badgeText.patient === 'Selected' ? 'disable-div' : ''"
        >
          <span
            class="position-absolute top-0 start-10 translate-middle p-2 px-5 border border-light rounded-pill text-white"
            :class="badgeText.patient === 'New' ? 'bg-success' : 'bg-primary'"
          >
            <strong>{{ badgeText.patient }}</strong>
          </span>
          <div class="my-4 row">
            <div class="col">
              <label class="form-check-label" for="patient_name">
                Patient's First Name
              </label>
              <input
                id="patient_name"
                v-model="BasicPatientInfo.first_name"
                type="text"
                class="form-control"
                name="name"
                required
              />
            </div>

            <div class="col">
              <label class="form-check-label" for="patient_name">
                Middle Name
              </label>
              <input
                id="patient_name2"
                v-model="BasicPatientInfo.middle_name"
                type="text"
                class="form-control"
                name="name"
              />
            </div>

            <div class="col">
              <label class="form-check-label" for="patient_name">
                Last Name
              </label>
              <input
                id="patient_name3"
                v-model="BasicPatientInfo.last_name"
                type="text"
                class="form-control"
                name="name"
              />
            </div>
          </div>

          <div class="my-4 row">
            <div class="col">
              <BaseSelectInput
                v-model="BasicPatientInfo.gender"
                :label="`Gender`"
                :options="genderOptions"
                :required="true"
              />
            </div>

            <div class="col">
              <label class="form-check-label" for="patient_date_of_birth">
                Date of Birth
              </label>

              <datepicker
                id="patient.date_of_birth"
                v-model="BasicPatientInfo.date_of_birth"
                :model-value="
                  convertToDateFormat(BasicPatientInfo.date_of_birth)
                "
                autocomplete="off"
                input-format="dd MMMM yyyy"
                s
                :upper-limit="currentYear"
                class="form-control"
                style="
                  --vdp-selected-bg-color: #32c3d6;
                  --vdp-hover-bg-color: #32c3d6;
                "
                starting-view="year"
                :typeable="true"
              />
            </div>
          </div>
        </div>

        <div class="my-5">
          <div class="my-4 row">
            <div class="col">
              <label class="form-check-label" for="patient_claim_number">
                Claim Number
              </label>
              <input
                v-model="patient.claim_number"
                class="form-control"
                type="number"
              />
            </div>

            <div class="col">
              <label class="form-check-label" for="email">Date of Injury</label>

              <datepicker
                v-model="patient.date_of_injury"
                :model-value="convertToDateFormat(patient.date_of_injury)"
                input-format="dd MMMM yyyy"
                autocomplete="off"
                class="form-control"
                :upper-limit="currentYear"
                style="
                  --vdp-selected-bg-color: #32c3d6;
                  --vdp-hover-bg-color: #32c3d6;
                "
                starting-view="year"
                :typeable="true"
              />
            </div>
          </div>

          <div class="row">
            <div class="col">
              <p class="text-dark">
                Was there an interpreter present during the consultation?
              </p>

              <BaseRadioGroup
                v-model="patient.has_interpreter"
                :label="`patient.has_interpreter`"
                :options="interpreterOptions"
              />
            </div>

            <div class="col">
              <p class="">Is patient accompanied by someone?</p>

              <BaseRadioGroup
                v-model="patient.is_accompanied"
                :label="`patient.is_accompanied`"
                :options="interpreterOptions"
              />
            </div>
          </div>
        </div>

        <div class="d-grid gap-2 col-4 mt-5 mx-auto">
          <button
            type="submit"
            class="btn btn-primary rounded-pill text-white my-3"
          >
            <strong>Save</strong>
          </button>
        </div>
      </fieldset>
    </form>
  </div>
  <div
    v-else
    class="min-vh-100 d-flex justify-content-center align-items-center"
  >
    <div>
      <div
        class="spinner-border text-primary"
        style="width: 12rem; height: 12rem"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vue3-datepicker'
import BaseRadioGroup from '@/components/Base/BaseRadioGroup.vue'
import BaseSelectInput from '@/components/Base/BaseSelectInput.vue'
import BaseBtn from '@/components/Base/BaseButton.vue'
import Editor from '@tinymce/tinymce-vue'
import Select2 from '@/components/Widget/SelectTwo.vue'
import dayjs from 'dayjs'

import { getLastDayOfYear, convertDateToAuFormat } from '@/utils/generics.js'
// import { parseISO } from 'date-fns'

export default {
  name: 'AdminForm',
  components: {
    Datepicker,
    BaseRadioGroup,
    Select2,
    BaseBtn,
    BaseSelectInput,
    Editor,
  },
  props: {
    assessmentId: { type: Number, default: null },
    readyNext: { type: Boolean },
    oldAssessmentData: {
      type: Object,
      default: null,
    },
  },

  emits: ['update:readyNext'],

  data() {
    return {
      patient: {},

      referral: {},

      referralList: [],
      referralSelectedInput: null,
      newReferrer: false,
      //
      patientList: [],
      patientSelectedInput: null,
      newPatient: false,
      //
      isNewForm: true,
      interpreterOptions: [
        {
          value: true,
          label: 'Yes',
        },
        {
          value: false,
          label: 'No',
        },
      ],
      genderOptions: [
        {
          value: 'M',
          label: 'Male',
        },
        {
          value: 'F',
          label: 'Female',
        },
      ],

      toasts: [],
      pageReady: false,
    }
  },

  computed: {
    currentYear() {
      return getLastDayOfYear()
    },

    badgeText() {
      let result = { referrer: '', patient: '' }

      this.BasicReferrerInfo && this.BasicReferrerInfo.id > 0
        ? (result.referrer = 'Selected')
        : this.BasicReferrerInfo
        ? (result.referrer = 'New')
        : ''
      this.BasicPatientInfo && this.BasicPatientInfo.id > 0
        ? (result.patient = 'Selected')
        : this.BasicPatientInfo
        ? (result.patient = 'New')
        : ''

      return result
    },

    BasicPatientInfo: {
      get() {
        return this.patient.patient_details
      },

      set(value) {
        this.patient.patient_details = value
      },
    },

    BasicReferrerInfo: {
      get() {
        return this.referral.referrer
      },

      set(value) {
        this.referral.referrer = value
      },
    },
  },

  async created() {
    await this.getReferralList()
    await this.getPatientList()
    this.pageReady = true
  },

  mounted() {
    //map old data of patient n referral
    //ID is most important

    let cloned = { ...this.oldAssessmentData }
    this.BasicPatientInfo = cloned.patient.patient_details
    this.BasicReferrerInfo = cloned.referral.referrer

    this.patient = cloned.patient
    this.referral = cloned.referral
  },

  methods: {
    clearNewInput(section) {
      section === 'referrer'
        ? (this.newReferrer = false)
        : section === 'patient'
        ? (this.newPatient = false)
        : ''
    },

    async getReferralList() {
      //default value
      let payload = { page: 1 }

      await this.$store
        .dispatch('getReferralList', payload)
        .then((response) => {
          let data = response.data

          this.referralList = data.results
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async getPatientList() {
      //default value
      let payload = { page: 1 }

      await this.$store
        .dispatch('getPatientList', payload)
        .then((response) => {
          let data = response.data

          this.patientList = data.results
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async postPatientInfo(obj) {
      let payload = obj

      await this.$store
        .dispatch('postNewPatient', payload)
        .then((response) => {
          let data = response.data

          this.BasicPatientInfo = data

          // Compile toast text
          this.toasts.push({
            status: 'success',
            content: `Successfully stored patient basic's information`,
          })
        })
        .catch((error) => {
          console.log(error)
          // Trigger toast text
          this.$root.toasts.push({
            status: 'danger',
            content: `Error: ${error.response.status}. Something went wrong when creating new patient data.`,
          })
        })
    },

    async putPatientInfo(obj) {
      let payload = { id: this.patientID, ...obj }

      await this.$store
        .dispatch('putPatientInfo', payload)
        .then((response) => {
          let data = response.data

          for (const [key, value] of Object.entries(data)) {
            if (key.includes('date')) {
              data[key] = this.parseStringToDate(value)
            }
          }

          this.patient = data ?? ''

          this.linkToAssessment(data.id)
        })
        .catch((error) => {
          console.log(error)
          // Trigger toast text
          this.$root.toasts.push({
            status: 'danger',
            content: `Error: ${error.response.status}. Something went wrong when updating new patient data.`,
          })
        })
    },

    async postRef(obj) {
      let payload = obj

      await this.$store
        .dispatch('postNewReferral', payload)
        .then((response) => {
          let data = response.data

          this.BasicReferrerInfo = data

          this.$root.toasts.push({
            status: 'success',
            content: `Successfully stored referrer's information`,
          })
        })
        .catch((error) => {
          console.log(error)

          error.response.status === 500
            ? this.$root.toasts.push({
                status: 'danger',
                content: `Ops, something went wrong in refferer's section. Please make sure you filled up all the field`,
              })
            : ''

          if (error.response.status === 400) {
            let textAry = []

            for (const [key, value] of Object.entries(error.response.data)) {
              textAry.push(`${key} field: ${value.join('')}`)
            }

            let finalErrorText = textAry.join('. ')
            this.$root.toasts.push({
              status: 'danger',
              content: `Refferer section error.  ${finalErrorText} `,
            })
          }
        })
    },

    async linkToAssessment(obj) {
      let payload = {}
      payload.id = this.assessmentId
      payload.payload = obj

      await this.$store
        .dispatch('patchAssessment', payload)
        .then((response) => {
          this.patient = response.data.patient
          this.referral = response.data.referral

          // Compile toast text
          this.toasts.push({
            status: 'success',
            content: `Successfully linked patient data to report`,
          })

          // Trigger overall toasts text
          this.$root.toasts = this.toasts
        })
        .catch((error) => {
          console.log(error)
        })
    },

    parseStringToDate(date) {
      return new Date(dayjs(date))
    },

    formatDate(date) {
      return convertDateToAuFormat(date)
    },

    convertToDateFormat(date) {
      if (date) {
        const result = new Date(date)

        return result
      } else return null
    },

    async submit() {
      let payload = {}

      payload.referral = this.referral
      payload.patient = this.patient

      if (this.badgeText.patient === 'New') {
        //format DOB
        let obj = this.BasicPatientInfo
        obj.date_of_birth = this.formatDate(obj.date_of_birth)

        await this.postPatientInfo(obj)

        payload.patient.patient_id = this.BasicPatientInfo.id
      } else if (this.badgeText.patient === 'Selected') {
        payload.patient.patient_id = this.BasicPatientInfo.id
      }

      if (this.badgeText.referrer === 'New') {
        await this.postRef({
          ...this.BasicReferrerInfo,
        })

        payload.referral.referrer_id = this.BasicReferrerInfo.id
      } else if (this.badgeText.referrer === 'Selected') {
        payload.referral.referrer_id = this.BasicReferrerInfo.id
      }

      //format all remaining dates
      payload.referral.letter_date
        ? (payload.referral.letter_date = this.formatDate(
            payload.referral.letter_date
          ))
        : null

      payload.patient.date_of_injury
        ? (payload.patient.date_of_injury = this.formatDate(
            payload.patient.date_of_injury
          ))
        : null

      await this.linkToAssessment(payload)

      this.$emit('update:readyNext', true)
    },
  },
}
</script>

<style lang="scss">
.vue-form {
  font-size: 16px;

  padding: 15px 30px;
  border-radius: 4px;
  margin: 50px auto;

  background-color: #fff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);

  h1 {
    font-size: 24px;
  }
}
.vue-form fieldset {
  margin: 24px 0 0 0;
}
.vue-form legend {
  padding-bottom: 10px;
  border-bottom: 1px solid #ecf0f1;
}

.vue-form h4,
.vue-form .label {
  color: #94aab0;
  margin-bottom: 10px;
}
.vue-form .label {
  display: block;
}

.vue-form input:focus,
.vue-form textarea:focus,
.vue-form select:focus {
  outline: none;
  border-color: #2c3e50;
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.2);
}

.refferrer-request {
  span {
    font-size: 0.95rem;
  }
}

//vue dropdown only
.vs-dropdown-listing {
  &:hover {
    background: #f6fdff;
  }
}
</style>
