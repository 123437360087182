<template>
  <div>
    <BaseRequiredLabel v-if="required" />
    <div
      v-for="option in options"
      :key="option"
      class="form-check my-1 has-validation"
      :class="inline ? 'form-check-inline' : ''"
    >
      <input
        :id="`${
          option.hasOwnProperty('value')
            ? `${label + '_' + option.value}`
            : `${label + '_' + option}`
        }`"
        v-model="model"
        :name="`${
          option.hasOwnProperty('value')
            ? `${label + '_' + option.value}`
            : `${label + '_' + option}`
        }`"
        type="radio"
        class="form-check-input me-2"
        :value="option.hasOwnProperty('value') ? option.value : option"
        :disabled="disabled"
        :class="{ 'is-invalid': error }"
        :required="required"
        :aria-describedby="`${label + '_RadioCheckboxFeedback'}`"
        @change="emitChange"
      />
      <label
        class="form-check-label"
        :class="additionalClass"
        :for="`${
          option.hasOwnProperty('value')
            ? `${label + '_' + option.value}`
            : `${label + '_' + option}`
        }`"
      >
        {{ option.label ? option.label : option }}
      </label>
    </div>
    <div :id="`${label + '_RadioCheckboxFeedback'}`" class="invalid-feedback">
      {{ error?.[0] }}
    </div>
  </div>
</template>

<script>
import BaseRequiredLabel from '@/components/Base/BaseRequiredLabel.vue'

export default {
  name: 'BaseRadioGroup',
  components: {
    BaseRequiredLabel,
  },
  props: {
    disabled: {
      type: Boolean,
    },

    required: {
      type: Boolean,
      default: false,
    },

    options: {
      type: Array,
      default: () => [],
    },

    inline: {
      type: Boolean,
      default: true,
    },

    modelValue: {
      type: [Boolean, String, Number, Array],
      default: null,
    },

    label: {
      type: String,
      default: '',
    },

    additionalClass: {
      type: String,
      default: '',
    },

    error: {
      type: Array,
      default: null,
    },
  },
  emits: ['update:modelValue'],

  data() {
    return {}
  },

  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },

  methods: {
    emitChange() {
      this.$emit('update:modelValue', this.model)
    },
  },
}
</script>

<style lang="scss"></style>
